import React, { useState } from 'react';
import ReactDom from 'react-dom';
import {Link} from 'react-router-dom';
function VenteForm() {
   
        return(
            <div class="main-container carousel bg_image">
        
            <div class="container">
        
                <div class="row fadeIn animated">
        
                    <div class="col-md-6">
        
                        <img src="assets/images/booking-1.png" alt="" class="img-responsive" />
        
                    </div>
                        <br/>
                    <div class="col-md-6 identifiant-bg">
        
                        
                        <p align="justify">
                    
                            <form>

                            <h3 class="title-style-1 text-center"> <i class="fa fa-plus"></i>  NOUVELLES VENTES <span class="title-under-1"></span>  </h3>
        


                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text"  id="name" class="form-control" name="name" placeholder="Nom de la vente aux enchères*" />
        
                                </div>
        
                                <div class="form-group col-md-6">
                                    <input type="text"  id="prix" class="form-control" name="prix"  placeholder="Prix minimum *"  />
                                </div>
                            </div>

                            <div class="row">
                            <div class="form-group col-md-6">
                            
                            <select id='transport'  class="form-control" aria-label="Langue">
                        <option defaultValue>Options de transport</option>
                        <option value="Livraison">Livraison </option>
                        <option value="Ramassage">Ramassage</option>
                    </select>
                                
                                 </div>

                                 <div class="form-group col-md-6">
                                    <input type="text"  id="Duree" class="form-control" name="Duree"  placeholder="Durée*"  />
                                   
                                </div>
                                </div>
                                <div class="row">
                                 
                                <div class="form-group col-md-6">
                                <select id='type'  class="form-control" aria-label="Langue">
                        <option defaultValue>Type d’enchères </option>
                        <option value=""> </option>
                        <option value=""></option>
                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                <select id='Categorie'  class="form-control" aria-label="Langue">
                        <option defaultValue>Catégorie </option>
                        <option value=""> </option>
                        <option value=""></option>
                    </select>
                                </div>
        
                               
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text"  id="mots" class="form-control" name="mots" placeholder="Mots clés*"  />
                                </div>
        
                                <div class="form-group col-md-6">
                                    <input type="text"  id="autre" class="form-control" name="autre"  placeholder="Autres informations*"  />
                                </div>
                              
                            </div>

                            
        
        
                            <div class="row">
                                
                            <div class="form-group col-md-6">
                                   <a href="#" class="hidden-xs bounceInUp animated slow"><i class="fa fa-money"></i> <Link to="./profils"> MES VENTES AUX ENCHÈRES</Link></a>
                                </div>
        
                                <div class="form-group col-md-12">
                                    <button type="submit" class="btn btn-primary pull-right"> <i class="fa fa-plus"></i> NOUVELLES VENTES</button>
                                    
                                         
                                </div>
        
                            </div>
        </form>
        
        <center>
                 <div class="control-group alert alert-success login-1" id="data_loading1">
                    <div class="controls" align="center">
                    <img src="assets/images/loading.gif" alt=""/>
                    </div>
                  </div></center>
                        </p>
        
                        <p align="justify">
                        
                        </p>
                    </div>
                </div> 
            </div>
        </div>
                )
    }
 
    
    export default VenteForm