import { makeObservable, observable, action } from "mobx";
import api from "../../api_config";

class RequestModel {
  requests = [];

  constructor() {
    makeObservable(this, {
      requests: observable,
      getRequests: action,
      addRequest: action,
      deleteRequest: action,
      submitRequest: action,
    });
  }

  async addRequest() {
    var user_id = localStorage.getItem("user_id");
    const response = await api.post(
      "requests/NewUserRequest/" + user_id,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (response) {
      this.requests.push(response.data);
    }
  }

  async deleteRequest(request) {
    const response = await api.delete("requests/RemovalRequest/" + request.id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response) {
      this.requests.remove(request);
    }
  }

  async submitRequest(request) {
    var directory = "C%3A%5CLaboratoires%5CRequests%5C";
    const response = await api.put(
      "requests/SubmissionRequest/" + request.id + "/" + directory,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (response) {
      this.requests.replace(
        this.requests.map((req) => {
          return req.id === request.id ? { ...response.data } : req;
        })
      );
    }
  }

  async getRequests() {
    var user_id = localStorage.getItem("user_id");
    const response = await api.get("requests/UserRequests/" + user_id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response) {
      this.requests.replace(response.data);
    }
  }
}

export default RequestModel;
