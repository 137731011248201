import React, {Component} from 'react';

class AccueilTable extends Component {
	
    render(){
        return(
          
            <div class="main-container carousel bg_image ">

                <div class="main-container">
                <p class="page-description">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit Necessitatibus.
			</p>
                <h4 class="page-title color-title text-center"><i class="fa fa-list"></i> VENTES AUX ENCHÈRES <span class="title-under"></span></h4>
                <p class="page-description">
				Lorem ipsum dolor sit amet, consectetur adipisicing elit Necessitatibus.
			</p>
                <div class="container">
          
                <table class="table table-style-1">
					      <thead>
					        <tr>
					          <th>No</th>
					          <th>Nom de la vente </th>
					          <th>Type d’enchères </th>
					          <th>Catégorie</th>
                              <th>Prix minimum </th>
					        </tr>
					      </thead>
					      <tbody>
					   
					   {this.props.encheres.map((enchere, index) => 
						<tr>
							<td>{index+1}</td>
							<td>{enchere?.nomVente}</td>
							<td>{enchere?.typeEnchere}</td>
							<td>{enchere?.categorie}</td>
							<td>{enchere?.prixMinimum}$</td>
						</tr>
					   )}
					      </tbody>
					    </table>
                </div>
                
                </div>
            
            </div>
        )
    }
}
export default AccueilTable