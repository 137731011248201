import React from "react";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/style.css';

import RechercheForm from "./UI/RechercheForm";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

class RechercheView extends React.Component {
  render() {
    

    return (
    
      <React.Fragment>
      <Header/>
        <RechercheForm />
        <Footer />
      </React.Fragment>
    
    );
  }
}

export default RechercheView;