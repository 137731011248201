import React from "react";
import { Redirect } from "react-router";
import ItemView from "./ItemView";

class ItemController extends React.Component {
  state = {
    id: "",
    description: "",
    quantity: 0,
    unitPrice: 0,
    view: "list",
    current_request: JSON.parse(localStorage.getItem("current_request")),
  };
  setDescription = (e) => {
    this.setState({ description: e.target.value });
  };
  setQuantity = (e) => {
    this.setState({ quantity: e.target.value });
  };
  setUnitPrice = (e) => {
    this.setState({ unitPrice: e.target.value });
  };
  getSelectedItem = () => {
    return {
      id: this.state.id,
      description:
        this.state.description === undefined ? "" : this.state.description,
      quantity: this.state.quantity === undefined ? 0 : this.state.quantity,
      unitPrice: this.state.unitPrice === undefined ? 0 : this.state.unitPrice,
    };
  };

  deleteItem = async (item) => {
    this.props.viewModel.deleteItem(item, this.state.current_request.id);
  };

  showItemForm = (item) => {
    if (item) {
      this.setState({
        id: item.id,
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
      });
    }
    this.setState({ view: "form" });
  };

  backRequests = () => {
    this.setState({ current_request: false });
    this.props.viewModel.requestStore.getRequests();
  };

  backItems = () => {
    this.setState({ view: "list" });
  };

  createUpdateItem = async () => {
    const item = this.getSelectedItem();
    if (
      item.description.trim() !== "" &&
      item.unitPrice > 0 &&
      item.quantity > 0
    ) {
      this.setState({ view: "list" });
      await this.props.viewModel.saveItem(item, this.state.current_request.id);
    } else {
      alert("All the inputs are required !!");
    }
  };

  getItems = async () => {
    await this.props.viewModel.getItems(this.state.current_request.id);
  };

  render() {
    const { viewModel } = this.props;
    if (!viewModel.userStore.getUser()) {
      return <Redirect to="/" />;
    } else if (!this.state.current_request) {
      return <Redirect to="/requests" />;
    } else {
      //this.getItems();
      return (
        <ItemView
          items={viewModel.store.items}
          item={this.getSelectedItem()}
          view={this.state.view}
          isSubmitted={this.state.current_request.isSubmitted}
          deleteItem={this.deleteItem}
          backRequests={this.backRequests}
          backItems={this.backItems}
          saveItem={this.createUpdateItem}
          showItemForm={this.showItemForm}
          setDescription={this.setDescription}
          setQuantity={this.setQuantity}
          setUnitPrice={this.setUnitPrice}
        />
      );
    }
  }
}

export default ItemController;
