import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AccueilTable from "./UI/AccueilTable";
import { observer } from "mobx-react";

const AccueilView = observer(
class AccueilView extends React.Component {
  
  render() {
    const encheres = [...this.props.encheres];
    
    return (
      <React.Fragment>
        <Header />
        <AccueilTable encheres={encheres} />
        <Footer />
      </React.Fragment>
    );
  }
});

export default AccueilView;