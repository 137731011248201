import React from "react";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const history = useHistory();

  const logoutClick = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <div>
      <div className="header-bar">
        <button className="btn btn-danger LogoutBtn" onClick={logoutClick}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Header;
