import React from "react";
import { observer } from "mobx-react";
import ItemCard from "./ItemCard";

const ItemList = observer(
  class ItemList extends React.Component {
    render() {
      return (
        <div>
          <div className="text-center">
            <button className="back-button" onClick={this.props.backRequests}>
              Back to the requests
            </button>
          </div>
          {this.props.items.length ? (
            <div>
              <div className="container mylist">
                <div className="row">
                  <div className="col-12">
                    <label className="_lbl">Items </label>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Unit price</th>
                          <th>Quantity</th>
                          <th>Total </th>
                          {!this.props.isSubmitted && <th width="25%"></th>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.items.map((item) => {
                          return (
                            <ItemCard
                              item={item}
                              key={item.id}
                              isSubmitted={this.props.isSubmitted}
                              onEdit={() => this.props.showItemForm(item)}
                              onDelete={() => this.props.deleteItem(item)}
                            ></ItemCard>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="emptyList-msg">0 items..</h1>
          )}
          {!this.props.isSubmitted && (
            <div className="text-center">
              <button
                className="btn-lg btn-secondary btn-rounded mb-4 _dsn"
                onClick={this.props.showItemForm}
              >
                New item
              </button>
            </div>
          )}
        </div>
      );
    }
  }
);
export default ItemList;
