import React from "react";
import { inject } from "mobx-react";
import ItemController from "./ItemController";
import ItemViewModel from "./ItemViewModel";
import RootStore from "../../models/RootStore";

@inject(RootStore.type.ITEM_MODEL)
class ItemProvider extends React.Component {
  constructor(props) {
    super(props);
    const itemModel = props[RootStore.type.ITEM_MODEL];
    this.viewModel = new ItemViewModel(itemModel);
  }

  render() {
    return <ItemController viewModel={this.viewModel} />;
  }
}

export default ItemProvider;
