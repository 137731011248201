import React, {Component, useState} from 'react';
import ReactDom from 'react-dom';
import {BrowserRouter, Link, NavLink, Switch, Router} from 'react-router-dom';

class Header extends Component {
  render(){
        return(
          
<header class="main-header">
       <nav class="navbar-static-top">

             <div class="navbar-top">

              <div class="container">
                  <div class="row">

                    <div class="col-sm-6 col-xs-12">

                        <ul class="list-unstyled list-inline header-contact">
                            <li> <i class="fa fa-phone"></i> <a href="tel:">+1(418) 732-1925 </a> </li>
                             <li> <i class="fa fa-envelope"></i> <a href="mailto:contact@nzuzi_mpingi@yahoo.fr">contact@encheresenligne.gouv.qc.ca</a> </li>
                       </ul> 
                      
                    </div>

                    <div class="col-sm-6 col-xs-12 text-right">

                        <ul class="list-unstyled list-inline header-social">

                            <li> <a href="#" target="_blank"> <i class="fa fa-facebook"></i> </a> </li>
                            <li> <a href="#" target="_blank"> <i class="fa fa-twitter"></i>  </a> </li>
                            <li> <a href="#" target="_blank"> <i class="fa fa-google"></i>  </a> </li>
                            <li> <a href="#" target="_blank"> <i class="fa fa-youtube"></i>  </a> </li>
                            <li> <a href="#" target="_blank"> <i class="fa fa fa-pinterest-p"></i>  </a> </li>
                       </ul> 
                      
                    </div>


                  </div>
              </div>

            </div>

            <div class="navbar-main fixme_1">
              
              <div class="container">

                <div class="navbar-header">
                  <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">

                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>

                  </button>
                  
                  <a class="navbar-brand" href="#"><i class="fa fa-money"></i> VOS ENCHÈRES EN LIGNE INC </a>
                  
                </div>

                <div id="navbar" class="navbar-collapse collapse pull-right" >

            
                  <ul class="nav ">

                    
                    <li><a class="is-active"><Link to="./"><i class="fa fa-home"></i> ACCUEIL</Link></a></li>
                      <li><a><Link to="./inscriptions"><i class="fa fa-edit"></i> INSCRIPTION</Link></a></li>
                   
                      <li class="has-child"><a><Link to="./logins"><i class="fa fa-sign-in"></i> IDENTIFICATION </Link></a>
                    
                      </li>
                      <li class="has-child"><a><Link to="./recherches"><i class="fa fa-search"></i> RECHERCHE </Link></a>
                    
                      </li>
                      
                  </ul>
                   
   
                </div> 

              </div> 
              
            </div> 


        </nav> 

    </header> 
    

            
        );
    }
  }
    export default Header
