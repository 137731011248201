import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Inscrire from "./UI/Inscrire";


class InscriptionView extends React.Component {
  render() {
    

    return (
      <React.Fragment>
       <Header />
       <Inscrire />
      <Footer />
      </React.Fragment>
    );
  }
}

export default InscriptionView;
