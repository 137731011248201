import React from "react";

class ItemForm extends React.Component {
  render() {
    const {
      item,
      onDescriptionChange,
      onUnitPriceChange,
      onQuantityChange,
      saveItem,
      backItems,
    } = this.props;

    return (
      <div className="text-center">
        <form className="modal-body mx-3">
          {item.id === undefined ? (
            <h3>Add a new item</h3>
          ) : (
            <h3>Update the item</h3>
          )}
          <div className="md-form mb-3">
            <label>Description</label>
            <input
              type="text"
              name="description"
              className="form-control validate"
              value={item.description}
              onChange={onDescriptionChange}
            />
          </div>
          <div className="md-form mb-3">
            <label>Unit price</label>
            <input
              type="number"
              name="unitPrice"
              className="form-control validate"
              value={item.unitPrice}
              onChange={onUnitPriceChange}
            />
          </div>
          <div className="md-form mb-3">
            <label>Quantity</label>
            <input
              type="number"
              name="quantity"
              className="form-control validate"
              value={item.quantity}
              onChange={onQuantityChange}
            />
          </div>
          <button
            type="button"
            className="btn btn-info svItm"
            onClick={saveItem}
          >
            Save
          </button>
          <button className="btn btn-info" onClick={backItems}>
            Back to items
          </button>
        </form>
      </div>
    );
  }
}

export default ItemForm;
