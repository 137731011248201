import { makeObservable, observable, action } from "mobx";
import api from "../../api_config";

class ItemModel {
  items = [];

  constructor() {
    makeObservable(this, {
      items: observable,
      getItems: action,
      createItem: action,
      deleteItem: action,
      updateItem: action,
    });
  }

  async createItem(item, request_id) {
    const response = await api.post(
      "requests/NewRequestItem/" + request_id,
      item,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (response) {
      this.items.push(response.data);
    }
  }

  async deleteItem(item, request_id) {
    const response = await api.delete(
      "requests/RemovalRequestItem/" + request_id + "/" + item.id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (response) {
      this.items.remove(item);
    }
  }

  async updateItem(item, request_id) {
    const response = await api.put("requests/UpdateItem/" + request_id, item, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response) {
      this.items.replace(
        this.items.map((itm) => {
          return itm.id === item.id ? { ...item } : itm;
        })
      );
    }
  }

  async getItems(request_id) {
    const response = await api.get("requests/RequestItems/" + request_id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response) {
      this.items.replace(response.data);
    }
  }
}

export default ItemModel;
