class VenteViewModel {
    constructor(store) {
      this.userStore = store["user"];
      this.requestStore = store["vente"];
    }
  
    async getVente(enchere_id) {
      await this.store.getVente(enchere_id);
    }
  
    async deleteVente(vente, enchere_id) {
      await this.store.deleteVente(vente, enchere_id);
    }
  
    async saveVente(vente, enchere_id) {
      if (vente.id === undefined) {
        await this.store.createVente(vente, enchere_id);
      } else {
        await this.store.updateVente(vente, enchere_id);
      }
    }
  }
  
  export default VenteViewModel;