class InscriptionViewModel {
  constructor(store) {
    this.store = store["item"];
    this.userStore = store["user"];
    this.requestStore = store["request"];
    this.requestStore = store["inscription"];
  }

  async getInscriptions(request_id) {
    await this.store.getInscriptions(request_id);
  }

  async deleteInscription(inscription, request_id) {
    await this.store.deleteInscription(inscription, request_id);
  }

  async saveInscription(inscription, request_id) {
    if (inscription.id === undefined) {
      await this.store.createInscription(inscription, request_id);
    } else {
      await this.store.updateInscription(inscription, request_id);
    }
  }
}

export default InscriptionViewModel;
