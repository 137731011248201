import React from "react";
import { inject } from "mobx-react";
import ProfilController from "./ProfilController";
import ProfilViewModel from "./ProfilViewModel";
import RootStore from "../../models/RootStore";

@inject(RootStore.type.USER_MODEL)
class ProfilProvider extends React.Component {
  constructor(props) {
    super(props);
    const ProfilModel = props[RootStore.type.USER_MODEL];
    this.viewModel = new ProfilViewModel(ProfilModel);
  }

  render() {
    return <ProfilController viewModel={this.viewModel} />;
  }
}

export default ProfilProvider;