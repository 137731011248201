import React from "react";
import { Redirect } from "react-router";
import RechercheView from "./RechercheView";

class RechercheController extends React.Component {


  render() {
   
      return (
        <RechercheView />
      );
    }
  }

export default RechercheController;