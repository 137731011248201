import React from "react";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/style.css';

import LoginForm from "./UI/LoginForm";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

class LoginView extends React.Component {
  render() {
    const { courriel, password, setEmail, setPassword, toLogin } = this.props;

    return (
    
      <React.Fragment>
      <Header/>
        <LoginForm
          courriel={courriel}
          password={password}
          toLogin={toLogin}
          onEmailChange={setEmail}
          onPasswordChange={setPassword}
        />
        <Footer />
      </React.Fragment>
    
    );
  }
}

export default LoginView;
