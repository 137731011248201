import React from "react";
import { Redirect } from "react-router";
import AccueilView from "./AccueilView";

class AccueilController extends React.Component {

  render() {
    const viewModel = this.props.viewModel;
    viewModel.store.getEncheres();

      return (
        <AccueilView encheres={viewModel.store.encheres} />
      );
    }
  }

export default AccueilController;