import React, {Component} from 'react';
import {Link} from 'react-router-dom';
class ProfilBody extends React.Component {
  render() {
    const { email, password, onEmailChange, onPasswordChange, toLogin } =
      this.props;

    return (
      <div class="main-container carousel bg_image ">
      <div class="main-container">
      <p class="page-description">
Lorem ipsum dolor sit amet, consectetur adipisicing elit Necessitatibus.
</p>
      <h4 class="page-title color-title text-center"><i class="fa fa-list"></i> MES VENTES AUX ENCHÈRES <span class="title-under"></span></h4>
      <p class="page-description">
Lorem ipsum dolor sit amet, consectetur adipisicing elit Necessitatibus.
</p>
      <div class="container">
      <div class="col-md-6 color-title"><i class="fa fa-plus"></i> <Link to="./ventes">NOUVELLES VENTES</Link></div>
      <table class="table table-style-1">
      <thead>
        <tr>
          <th>No</th>
          <th>Nom de la vente </th>
          <th>Type d’enchères </th>
          <th>Catégorie</th>
           <th>Prix minimum </th>
           <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>100.00</td>
          <td><i class="fa fa-edit"></i> <Link to="./modifiers">MODIFIER</Link> <i class="fa fa-trash"></i> <Link to="./supprimers">SUPPRIMER</Link></td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
          <td>100.00</td>
          <td><i class="fa fa-edit"></i> <Link to="./modifiers">MODIFIER</Link> <i class="fa fa-trash"></i> <Link to="./supprimers">SUPPRIMER</Link></td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>Larry</td>
          <td>the Bird</td>
          <td>@twitter</td>
         <td>100.00</td>
         <td><i class="fa fa-edit"></i> <Link to="./modifiers">MODIFIER</Link> <i class="fa fa-trash"></i> <Link to="./supprimers">SUPPRIMER</Link></td>
        </tr>
      </tbody>
      
    </table>
   
      </div>
      
      </div>
      
      
  </div>
    );
  }
}

export default ProfilBody;
