import React, {Component} from 'react';
import {Link} from 'react-router-dom';
class RechercheForm extends React.Component {
  render() {
    const { email, password, onEmailChange, onPasswordChange, toLogin } =
      this.props;

    return (
      <div class="main-container carousel bg_image">

      <div class="container">
  
          <div class="row fadeIn animated">
  
              <div class="col-md-6">
  
                  <img src="assets/images/booking-1.png" alt="" class="img-responsive" />
  
              </div>
                  <br/>
              <div class="col-md-6 identifiant-bg">
  
                  
                  <p align="justify">
              
                      <form>
                      <h3 class="title-style-1 text-center"> <i class="fa fa-search"></i> RECHERCHE  <span class="title-under-1"></span>  </h3>
                 
  
                      <div class="row">
                          <div class="form-group col-md-15">
                              <input type="text" id="email" class="form-control" name="email" value={email}
                onChange={onEmailChange} placeholder="Rechercher une enchère *"  />
                          </div>
  
                        
                      </div>
                     
                 
  
                      <div class="row">
  
                          <div class="form-group col-md-12">
                              <button  type="button" class="btn btn-primary pull-right" name="but" onClick={toLogin}> <i class="fa fa-search"></i> RECHERCHER</button>
                                   
                          </div>
  
                      </div>
  </form>
  
  <center>
           <div class="control-group alert alert-success login-1" id="data_loading1">
              <div class="controls" align="center">
              <img src="assets/images/loading.gif" alt=""/>
              </div>
            </div></center>
                  </p>
  
                  <p align="justify">
                  
                  </p>
              </div>
          </div> 
      </div>
  </div>
    );
  }
}

export default RechercheForm;
