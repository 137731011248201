import api from "../../api_config";
import { makeObservable, observable, action } from "mobx";

class AcceuilModel {
    encheres = [];

    constructor() {
        makeObservable(this, {
          encheres: observable,
          getEncheres: action,
        });
      }

    // Ajouter
    async createEncherer(item) {
        const response = await api.post("encheres/", item, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        if (response) {
            this.items.push(item);
        }
    }
    
    //Lister
    async getEncheres() {
        const response =  await api.get("encheres");
        if(response){
            this.encheres.replace(response.data);
        }
    }
}

export default AcceuilModel;
