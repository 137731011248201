import React from "react";
import { Redirect } from "react-router";
import ProfilView from "./ProfilView";

class ProfilController extends React.Component {


  render() {
   
      return (
        <ProfilView />
      );
    }
  }


export default ProfilController;