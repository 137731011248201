import React from "react";
import { inject } from "mobx-react";
import RechercheController from "./RechercheController";
import RechercheViewModel from "./RechercheViewModel";
import RootStore from "../../models/RootStore";

@inject(RootStore.type.USER_MODEL)
class RechercheProvider extends React.Component {
  constructor(props) {
    super(props);
    const RechercheModel = props[RootStore.type.USER_MODEL];
    this.viewModel = new RechercheViewModel(RechercheModel);
  }

  render() {
    return <RechercheController viewModel={this.viewModel} />;
  }
}

export default RechercheProvider;