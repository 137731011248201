import React, {Component} from 'react';
import ReactDom from 'react-dom';
import {Link} from 'react-router-dom';

class Erreur404 extends Component {
    render(){
        return(
          
            <div class="main-container carousel bg_image">
                <div class="row fadeIn animated">
                <div class="col-md-6">
                <img src="assets/images/Amuru_District_in_Uganda1.png" alt="" class="img-responsive"/>
                </div>
                <div class="col-md-6 recherche">
                <p align="justify">
               
                <h3 class="title-style-1 text-center"> <i class="fa fa-exclamation-triangle"></i> PAGE NON TROUVÉE! <span class="title-under-1"></span></h3>
                <h1 class="text-center erreur-1"> 404</h1>
                <h2 class="text-center"> Oups, la page que vous recherchez n'existe pas.</h2>
                <p align="justify" class="text-center"><Link to="./">Vous voudrez peut-être revenir à la page d'accueil.</Link></p>
                </p>
                </div>
                
                </div>
            
            </div>
        )
    }
}
export default Erreur404