import React from "react";

class ItemCard extends React.Component {
  render() {
    const { item, isSubmitted, onDelete, onEdit } = this.props;

    return (
      <tr>
        <td>{item.description}</td>
        <td>{item.unitPrice}</td>
        <td>{item.quantity}</td>
        <td>{item.unitPrice * item.quantity}</td>
        {!isSubmitted && (
          <td className="text-center">
            <button className="btn btn-primary" onClick={onEdit}>
              Edit
            </button>
            <button className="btn btn-outline-danger" onClick={onDelete}>
              Delete
            </button>
          </td>
        )}
      </tr>
    );
  }
}

export default ItemCard;
