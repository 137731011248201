import RequestModel from "./domain/RequestModel";
import ItemModel from "./domain/ItemModel";
import UserModel from "./domain/UserModel";
import AcceuilModel from "../components/Accueil/AcceuilModel";

class RootStore {
  static type = {
    REQUEST_MODEL: "requestModel",
    USER_MODEL: "userModel",
    ITEM_MODEL: "itemModel",
    HOME_MODEL: "homeModel",
  };

  constructor() {
    this.requestModel = new RequestModel();
    this.itemModel = new ItemModel();
    this.UserModel = new UserModel();
    this.AcceuilModel = new AcceuilModel();
  }

  getStores = () => {
    return {
      [RootStore.type.HOME_MODEL]: {
        acceuil: this.AcceuilModel,
        item: this.itemModel,
        user: this.UserModel,
        request: this.requestModel,
      },
      [RootStore.type.ITEM_MODEL]: {
        item: this.itemModel,
        user: this.UserModel,
        request: this.requestModel,
      },
      [RootStore.type.USER_MODEL]: {
        user: this.UserModel,
        request: this.requestModel,
      },
    };
  }
}

export default RootStore;
