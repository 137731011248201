class ItemViewModel {
  constructor(store) {
    this.store = store["item"];
    this.userStore = store["user"];
    this.requestStore = store["request"];
  }

  async getItems(request_id) {
    await this.store.getItems(request_id);
  }

  async deleteItem(item, request_id) {
    await this.store.deleteItem(item, request_id);
  }

  async saveItem(item, request_id) {
    if (item.id === undefined) {
      await this.store.createItem(item, request_id);
    } else {
      await this.store.updateItem(item, request_id);
    }
  }
}

export default ItemViewModel;
