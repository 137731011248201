class AccueilViewModel {
  constructor(store) {
    this.store = store.acceuil;
  }

  async getInscriptions(request_id) {
    await this.store.getInscriptions(request_id);
  }
}
  
export default AccueilViewModel;