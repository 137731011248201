import React from "react";
import Header from "../Header";
import ItemForm from "./UI/ItemForm";
import ItemList from "./UI/ItemList";

class ItemtView extends React.Component {
  render() {
    const {
      item,
      items,
      deleteItem,
      saveItem,
      backItems,
      backRequests,
      showItemForm,
      view,
      isSubmitted,
      setDescription,
      setQuantity,
      setUnitPrice,
    } = this.props;

    return (
      <React.Fragment>
        <Header />
        {view === "form" ? (
          <ItemForm
            item={item}
            onDescriptionChange={setDescription}
            onQuantityChange={setQuantity}
            onUnitPriceChange={setUnitPrice}
            saveItem={saveItem}
            backItems={backItems}
          />
        ) : (
          <ItemList
            deleteItem={deleteItem}
            showItemForm={showItemForm}
            backRequests={backRequests}
            isSubmitted={isSubmitted}
            items={items}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ItemtView;
