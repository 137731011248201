import React from "react";
import Footer from "../Footer/Footer";
import VenteForm from "./UI/VenteForm";
import HeaderSession from "../Header/HeaderSession";


class VenteView extends React.Component {
  render() {
    

    return (
      <React.Fragment>
       <HeaderSession />
       <VenteForm />
      <Footer />
      </React.Fragment>
    );
  }
}

export default VenteView;