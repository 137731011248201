import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Erreur404 from "./UI/Erreur404";


class ErreurView extends React.Component {
  render() {
    
    return (
      <React.Fragment>
       <Header />
       <Erreur404 />
      <Footer />
      </React.Fragment>
    );
  }
}

export default ErreurView;
