import api from "../../api_config";

class UserModel {
  user = {};

  async toLogin(_user) {
    try {
      const response = await api.post("authorization/login", _user);
      this.user = response.data;
      localStorage.setItem("token", this.user.token);
      localStorage.setItem("user_id", this.user.userToReturn.id);
      return true;
    } catch (error) {
      return false;
    }
  }

  async createUser() {
    try {
      const response = await api.post("authorization/login", {
        courriel: "toto@uqar.ca",
        password: "toto",
      });
    } catch (error) {
      await api.post("authorization/registration", {
        nom: "fname",
        prenom: "lname",
        courriel: "toto@uqar.ca",
        password: "toto",
      });
    }
  }

  getUser() {
    return localStorage.getItem("token") ? this.user : false;
  }
}

export default UserModel;
