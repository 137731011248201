import React from "react";
import { inject } from "mobx-react";
import InscriptionController from "./InscriptionController";
import InscriptionViewModel from "./InscriptionViewModel";
import RootStore from "../../models/RootStore";

@inject(RootStore.type.ITEM_MODEL)
class InscriptionProvider extends React.Component {
  constructor(props) {
    super(props);
    const inscriptionModel = props[RootStore.type.ITEM_MODEL];
    this.viewModel = new InscriptionViewModel(inscriptionModel);
  }

  render() {
    return <InscriptionController viewModel={this.viewModel} />;
  }
}

export default InscriptionProvider;
