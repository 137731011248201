import React from "react";
import { Redirect } from "react-router";
import InscriptionView from "./InscriptionView";

class InscriptionController extends React.Component {
  
  setDescription = (e) => {
    this.setState({ description: e.target.value });
  };
  setQuantity = (e) => {
    this.setState({ quantity: e.target.value });
  };
  setUnitPrice = (e) => {
    this.setState({ unitPrice: e.target.value });
  };
  getSelectedItem = () => {
    return {
      id: this.state.id,
      description:
        this.state.description === undefined ? "" : this.state.description,
      quantity: this.state.quantity === undefined ? 0 : this.state.quantity,
      unitPrice: this.state.unitPrice === undefined ? 0 : this.state.unitPrice,
    };
  };

 // deleteInscription = async (item) => {
    //this.props.viewModel.deleteInscription(inscription, this.state.current_request.id);
 // };

  showItemForm = (item) => {
    if (item) {
      this.setState({
        id: item.id,
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
      });
    }
    this.setState({ view: "form" });
  };

  backRequests = () => {
    this.setState({ current_request: false });
    this.props.viewModel.requestStore.getRequests();
  };

  backItems = () => {
    this.setState({ view: "list" });
  };

  createUpdateInscription = async () => {
    const item = this.getSelectedInscription();
    if (
      item.description.trim() !== "" &&
      item.unitPrice > 0 &&
      item.quantity > 0
    ) {
     // this.setState({ view: "list" });
      //await this.props.viewModel.saveInscription(inscription, this.state.current_request.id);
    } else {
      alert("All the inputs are required !!");
    }
  };

  getInscriptions = async () => {
    await this.props.viewModel.getInscriptions(this.state.current_request.id);
  };

  render() {

      return (
        <InscriptionView
        
        />
      );
    
  }
}

export default InscriptionController;
