import React, {Component} from 'react';

class Footer extends Component {
    render(){
        return(
            <footer class="main-footer">

        <div class="footer-top">
            <div class="page-heading1 text-center bg_image">

        <div class="container zoomIn animated">
            <div id="overlay">
            <h1 class="page-title"><span class="title-underm"></span></h1>
            <p class="page-description">
            
            </p>
            
        </div>
</div>
    </div>
        </div>


        <div class="footer-main">
            <div class="container">
            
            </div>

            
        </div>

        <div class="footer-bottom">

            <div class="container text-center">
            <i class="fa fa-money"></i> VOS ENCHÈRES EN LIGNE INC @ copyrights 2022 
            </div>
        </div>
        
    </footer>
        )
    }
}
export default Footer