import React from "react";
import { inject } from "mobx-react";
import AccueilController from "./AccueilController";
import AccueilViewModel from "./AccueilViewModel";
import RootStore from "../../models/RootStore";

@inject(RootStore.type.HOME_MODEL)
class AccueilProvider extends React.Component {
  constructor(props) {
    super(props);
    const AccueilModel = props[RootStore.type.HOME_MODEL];
    this.viewModel = new AccueilViewModel(AccueilModel);
  }

  render() {
    return <AccueilController viewModel={this.viewModel} />;
  }
}

export default AccueilProvider;