import React from "react";
import { inject } from "mobx-react";
import VenteController from "./VenteController";
import VenteViewModel from "./VenteViewModel";
import RootStore from "../../models/RootStore";

@inject(RootStore.type.ITEM_MODEL)
class VenteProvider extends React.Component {
  constructor(props) {
    super(props);
    const VenteModel = props[RootStore.type.ITEM_MODEL];
    this.viewModel = new VenteViewModel(VenteModel);
  }

  render() {
    return <VenteController viewModel={this.viewModel} />;
  }
}

export default VenteProvider;