import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { Provider } from "mobx-react";
import RechercheProvider from "./components/Recherche/RechercheProvider";
import ProfilProvider from "./components/Profil/ProfilProvider";
import ItemProvider from "./components/item/ItemProvider";
import LoginProvider from "./components/login/LoginProvider";
import AccueilProvider from "./components/Accueil/AccueilProvider";
import RootStore from "./models/RootStore";

import InscriptionProvider from "./components/Inscription/InscriptionProvider";
import ErreurView from "./components/404/ErreurView";
import VenteProvider from "./components/Vente/VenteProvider";

const rootStore = new RootStore();

class App extends Component {
  render() {
    return (
      <Provider {...rootStore.getStores()}>
        <div>
          <Router>
            <Switch>
              <Route path="/" exact render={() => <AccueilProvider />} />
              <Route path="/logins"  render={() => <LoginProvider />} />
              <Route path="/profils" render={() => <ProfilProvider />} />
              <Route path="/items" render={() => <ItemProvider />} />
              <Route path="/inscriptions" render={() => <InscriptionProvider />} />
              <Route path="/recherches" render={() => <RechercheProvider />} />
              <Route path="/ventes" render={() => <VenteProvider />} />
              <Route  render={() => <ErreurView />}  />
            </Switch>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
