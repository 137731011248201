import React, { useState } from 'react';
import ReactDom from 'react-dom';
import {Link} from 'react-router-dom';
function Inscrire() {
   
        return(
            <div class="main-container carousel bg_image">
        
            <div class="container">
        
                <div class="row fadeIn animated">
        
                    <div class="col-md-6">
        
                        <img src="assets/images/booking-1.png" alt="" class="img-responsive" />
        
                    </div>
                        <br/>
                    <div class="col-md-6 identifiant-bg">
        
                        
                        <p align="justify">
                    
                            <form>

                            <h3 class="title-style-1 text-center"> <i class="fa fa-edit"></i> INSCRIPTION <span class="title-under-1"></span>  </h3>
        


                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text"  id="first_name" class="form-control" name="first_name" placeholder="Nom*" />
        
                                </div>
        
                                <div class="form-group col-md-6">
                                    <input type="text"  id="last_name" class="form-control" name="last_name"  placeholder="Prenom*"  />
                                </div>
                            </div>

                            <div class="row">
                            <div class="form-group col-md-6">
                            
                                    <input type="text"   id="username" class="form-control" name="username"  placeholder="Nom d’utilisateur *"  />
                                
                                 </div>

                                 <div class="form-group col-md-6">
                                    <input type="date"  id="birth_date" class="form-control" name="birth_date"  placeholder="Date de naissance*"  />
                                   
                                </div>
                                </div>
                                <div class="row">
                                 
                                <div class="form-group col-md-6">
                                    <input type="mail_address"  id="mail_address"  class="form-control" name="email" placeholder="Adresse courriel*"  />
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="mail_address"   id="mail_address_v" class="form-control" name="email_v"  placeholder="Vérification de courriel *"  />
                                </div>
        
                               
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="password"  id="password" class="form-control" name="password" placeholder="Mot de passe*"  />
                                </div>
        
                                <div class="form-group col-md-6">
                                    <input type="password"  id="confirmationPassword" class="form-control" name="confirmationPassword"  placeholder="Confirmez le mot de passe*"  />
                                </div>
                              
                            </div>

                            <div class="row">
                            <div class="form-group col-md-6">
                            <select id='Langue'  class="form-control" aria-label="Langue">
                        <option defaultValue>Français</option>
                        <option value="français">français</option>
                        <option value="Englais">Englais</option>
                    </select>
                    </div>
                            </div>
        
        
                            <div class="row">
                                
                            <div class="form-group col-md-6">
                                   <a href="#" class="hidden-xs bounceInUp animated slow"><i class="fa fa-user"></i> <Link to="./"> Déjà utilisateur?</Link></a>
                                </div>
        
                                <div class="form-group col-md-12">
                                    <button type="submit" class="btn btn-primary pull-right"> <i class="fa fa-edit"></i> S'INSCRIRE</button>
                                    
                                         
                                </div>
        
                            </div>
        </form>
        
        <center>
                 <div class="control-group alert alert-success login-1" id="data_loading1">
                    <div class="controls" align="center">
                    <img src="assets/images/loading.gif" alt=""/>
                    </div>
                  </div></center>
                        </p>
        
                        <p align="justify">
                        
                        </p>
                    </div>
                </div> 
            </div>
        </div>
                )
    }
 
    
    export default Inscrire