import React from "react";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/style.css';

import ProfilBody from "./UI/ProfilBody";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HeaderSession from "../Header/HeaderSession";

class ProfilView extends React.Component {
  render() {
    

    return (
    
      <React.Fragment>
      <HeaderSession/>
        <ProfilBody />
        <Footer />
      </React.Fragment>
    
    );
  }
}

export default ProfilView;